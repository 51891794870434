import React from "react";
import { DateRangePickerProps, DateRangeOptions } from "./types";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CustomRangePickerDialog from "./CustomRangePickerDialog";

import { useState, useCallback } from "react";
import { useDateRange } from "./DateRangeProvider";
import moment from "moment/moment";

const DateRangePicker: React.FC<DateRangePickerProps> = props => {
	const {
		options = ["today", "this-week", "this-month", "this-year", "reset"],
	} = props;

	const dateRange = useDateRange();
	const [label, setLabel] = useState("Select Date");
	const [custDialogOpen, setCustDialogOpen] = useState(false);

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleChange = useCallback(
		(rangeType: DateRangeOptions) => {
			let from: Date | undefined = new Date();
			let to: Date | undefined = new Date();

			if (rangeType !== "reset") {
				// Fix time component from the range. Set 12am for "from" date and 11:59pm for "to" date.
				from = new Date(from.toDateString());
				to = new Date(to.toDateString());
				to.setDate(to.getDate() + 1);
				to.setMilliseconds(-1);
			}

			switch (rangeType) {
				case "this-week":
					from.setDate(from.getDate() - from.getDay());
					setLabel(
						`This Week | ${moment(from).format("MMM DD")} - ${moment(to).format(
							"MMM DD"
						)}`
					);
					break;
				case "this-month":
					from.setDate(1);
					setLabel(
						`This Month | ${moment(from).format("MMM DD")} - ${moment(to).format(
							"MMM DD"
						)}`
					);
					break;
				case "this-year":
					from.setMonth(0);
					from.setDate(1);
					setLabel(
						`This Year | ${moment(from).format("MMM DD")} - ${moment(to).format(
							"MMM DD"
						)}`
					);
					break;
				case "today":
					setLabel(
						`Today | ${moment(from).format("hh:mm A")} - ${moment(to).format(
							"hh:mm A"
						)}`
					);
					break;
				case "reset":
				default:
					from = undefined;
					to = undefined;
					setLabel("Reset");
					break;
			}

			dateRange.update(from, to);
			setAnchorEl(null);
		},
		[dateRange]
	);

	return (
		<>
			<ButtonGroup variant="contained" color="secondary">
				<Tooltip
					title={`${moment(dateRange?.from).format(
						"YYYY/MM/DD hh:mm a"
					)} -- ${moment(dateRange?.to).format("YYYY/MM/DD hh:mm a")}`}
				>
					<Button
						startIcon={<DateRangeIcon />}
						style={{ textTransform: "initial" }}
						onClick={() => setCustDialogOpen(true)}
					>
						{label}
					</Button>
				</Tooltip>
				<Button onClick={ev => setAnchorEl(ev.currentTarget)} size="small">
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>

			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={!!anchorEl}
				onClose={() => setAnchorEl(null)}
			>
				{options.length &&
					options.map((op, i) => (
						<MenuItem
							key={`${op}-${i}`}
							style={{ textTransform: "capitalize" }}
							onClick={() => handleChange(op)}
						>
							{op.replace("-", " ")}
						</MenuItem>
					))}
			</Menu>

			<CustomRangePickerDialog
				open={custDialogOpen}
				initialValues={dateRange}
				onDone={range => {
					dateRange.update(range?.from, range?.to);
					setLabel(
						`${moment(range?.from).format("MMM DD, YYYY")} - ${moment(
							range?.to
						).format("MMM DD, YYYY")}`
					);
					setCustDialogOpen(false);
				}}
				onCancel={() => setCustDialogOpen(false)}
			/>
		</>
	);
};

export default DateRangePicker;
