import * as authStore from "../../core/providers/AuthenticationProvider/storage";

export const generateId = (): string => Math.random().toString(36).substr(2, 9);
export const IS_DEV_MODE =
	process.env.NODE_ENV === "development" ||
	window.location.href.includes("productiontrack-dev") ||
	window.location.href.includes("productiontrack-moment");

export const visit3hd = (path: string) => {
	const url = "https://" + process.env.REACT_APP_QR_BASENAME || "3hd.us";
	// const url = "https://moment-336.3hd.us";
	const authData = {
		access_token: authStore.getToken(),
		refresh_token: authStore.getRefreshToken(),
		org_slug: authStore.getOrganization(),
		curr_person: authStore.getCurrPerson(),
	};

	// Open a new window or tab
	const popup = window.open(url + path, "_blank");

	if (popup) {
		setTimeout(() => {
			popup.postMessage(authData, url);
			console.log("Auto login initiated");
		}, 1500); // Adjust delay as needed
	} else {
		console.error("Failed to open popup. Check browser settings.");
	}
};
