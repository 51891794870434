import React from "react";
import { DateRange, DateRangeProviderProps } from "./types";
import dateRangeContext from "./context";

import { useState } from "react";

const DateRangeProvider: React.FC<DateRangeProviderProps> = props => {
	const [range, setRange] = useState<DateRange | undefined>(props?.initialValue);

	return (
		<dateRangeContext.Provider
			value={{
				...range,
				update: (from, to) =>
					setRange(currRange => ({
						from: from,
						to: to,
					})),
			}}
		>
			{props.children}
		</dateRangeContext.Provider>
	);
};

export default DateRangeProvider;
