import React from "react";
import Highcharts from "highcharts";
import Highcgarts3d from "highcharts/highcharts-3d";
import HighchartsDrilldown from "highcharts/modules/drilldown";
import HighchartsReact from "highcharts-react-official";

import { useFirstTimeRightReport } from "../../../../../core/providers/PlaceReportProvider";
import { useDateRange } from "../../../../../core/components/DateRangePicker";

Highcgarts3d(Highcharts);
HighchartsDrilldown(Highcharts);

const FirstTimeRightPie: React.FC = () => {
	const dateRange = useDateRange();
	const ftrReport = useFirstTimeRightReport({
		from: dateRange?.from || new Date(0),
		to: dateRange?.to || new Date(),
	});
	if (!ftrReport) return null;

	const [totalFirstTimeRight, totalRework] = ftrReport;

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={{
				chart: {
					plotShadow: false,
					type: "pie",
				},
				tooltip: {
					pointFormat: "{series.name}: <b>{point.y}</b>",
				},
				accessibility: {
					point: {
						valueSuffix: "%",
					},
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: "pointer",
						dataLabels: {
							enabled: false,
							format:
								'<span style="font-weight: 400;">{point.name}</span>: <b>{point.percentage:.0f} %</b>',
						},
						showInLegend: true,
					},
				},
				title: null,
				series: [
					{
						name: "Quantity",
						colorByPoint: true,
						type: "pie",
						data: [
							{
								name: "First time right",
								y: totalFirstTimeRight,
							},
							{
								name: "Rework",
								y: totalRework,
							},
						],
					},
				],
			}}
		/>
	);
};

export default FirstTimeRightPie;
