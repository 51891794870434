import React, { useState, useRef } from "react";
import { IPlaceActionsProps } from "./types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import PlaceForm, {
	PlaceFormRef,
	PlaceFormData,
} from "../../core/forms/PlaceForm";
import usePlaces from "../../core/hooks/use-places";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const PlaceActions: React.FC<IPlaceActionsProps> = ({ place }) => {
	const placeForm = useRef<PlaceFormRef>(null);
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [, loading, { update: updatePlace }] = usePlaces();

	const handleSave = async (data: PlaceFormData) =>
		updatePlace(place.id, data).then(
			updatedPlace => !!updatedPlace && setEditDialogOpen(false)
		);

	useEffect(() => {
		if (place.isEditOpen) {
			setEditDialogOpen(true);
		}
	}, [place.isEditOpen]);

	const history = useHistory();
	return (
		<>
			<Grid
				container
				alignItems="center"
				spacing={1}
				justifyContent="flex-end"
				wrap="nowrap"
			>
				<Grid item>
					<IconButton onClick={() => setEditDialogOpen(true)} color="secondary">
						<Icon>edit</Icon>
					</IconButton>
				</Grid>
			</Grid>

			{/* BEGIN: Place editing dialog. */}
			<Dialog
				open={editDialogOpen}
				onClose={() => setEditDialogOpen(false)}
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle>
					Edit place "<b>{place.name}</b>"
				</DialogTitle>
				<DialogContent>
					<Box pb={4}>
						<PlaceForm
							ref={placeForm}
							initialValues={place}
							onSubmit={handleSave}
							disabled={loading}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setEditDialogOpen(false);
							history.push("/places");
						}}
						disabled={loading}
					>
						Cancel
					</Button>

					<Button
						onClick={() => placeForm.current?.submitForm()}
						disabled={loading}
						color="primary"
						variant="contained"
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default PlaceActions;
