import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Card } from "@material-ui/core";
import {
	PlaceActivityLog,
	usePlaceReport,
} from "../../../../../core/providers/PlaceReportProvider";
import moment from "moment";
import { useDateRange } from "../../../../../core/components/DateRangePicker";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";

// Initialize the module
NoDataToDisplay(Highcharts);
export const groupLogsByDate = (logs: PlaceActivityLog[]) => {
	// this gives an object with dates as keys
	const groups = logs.reduce(
		(data: { [x: string]: PlaceActivityLog[] }, item) => {
			const date: any = item.arrived_at.split(" ")[0];
			if (!data[date]) {
				data[date] = [];
			}
			data[date].push(item);
			return data;
		},
		{}
	);

	// to add it in the array format instead
	return Object.keys(groups).map(date => {
		return {
			date,
			logs: groups[date],
		};
	});
};

const TaktTimeChart = () => {
	const placeReport = usePlaceReport();

	// Sort logs by `arrived_at` in descending order
	const sortedLogs =
		placeReport?.logs?.sort(
			(a, b) => new Date(b.arrived_at).getTime() - new Date(a.arrived_at).getTime()
		) || [];

	// Calculate differences between consecutive timestamps
	const times_adjusted =
		sortedLogs?.slice(1).map((el, index) => {
			const currentTime = new Date(sortedLogs[index].arrived_at).getTime() / 1000;
			const nextTime = new Date(el.arrived_at).getTime() / 1000;
			return currentTime - nextTime;
		}) || [];

	const dateRange = useDateRange();
	const title = dateRange?.from
		? `${placeReport?.name} Takt Time (${moment(dateRange?.from).format(
				"MMM DD"
		  )} - ${moment(dateRange?.to).format("MMM DD")})`
		: `${placeReport?.name} Takt Time`;

	// Calculate the average takt time
	const average_time_adjusted =
		times_adjusted.reduce((sum, time) => sum + time, 0) / times_adjusted.length;

	const options = {
		chart: {
			type: "areaspline", // Using areaspline for shading
			height: 550,
			backgroundColor: "transparent",
			style: {
				fontFamily: "Arial, sans-serif",
			},
		},
		noData: {
			style: {
				fontWeight: "bold",
				fontSize: "24px",
			},
		},
		title: {
			text: title,
			align: "left",
			style: {
				fontSize: "24px",
			},
		},
		xAxis: {
			categories: placeReport?.logs?.map(
				el => el?.product?.part_number || el?.license_plate?.part_number
			),
			title: {
				text: `Last ${placeReport?.logs?.length} Unit's Part Numbers`,
				margin: 10,
			},
		},
		yAxis: {
			title: {
				text: "Time (seconds)",
			},
			plotLines: [
				{
					value: average_time_adjusted,
					color: "gray",
					dashStyle: "dash",
					width: 2,
					label: {
						text: "Average Time",
						style: {
							color: "white",
							backgroundColor: "gray",
						},
					},
				},
			],
			gridLineWidth: 0.5,
			gridLineColor: "lightgray",
		},
		tooltip: {
			shared: true,
			crosshairs: true,
			valueDecimals: 4,
			formatter: function (
				this: Highcharts.TooltipFormatterContextObject
			): string {
				// Ensure the tooltip points are valid
				if (!this.points || !placeReport?.logs) return "";

				// Retrieve the current point's index
				const dateIndex = this.points[0]?.point?.index as number;

				// Retrieve and format the date using Moment.js
				const rawDate = placeReport?.logs[dateIndex]?.arrived_at;
				if (!rawDate) return "";

				const formattedDate = moment(rawDate).format("MMM. Do, YYYY - hh:mm:ss A");

				// Combine the formatted date with the y-axis value
				return `Part #: ${
					this.points[0].x
				} arrived at <b>${formattedDate}</b><br/>Time Difference: ${this.points[0].y.toLocaleString()} seconds`;
			},
		},
		legend: {
			enabled: false, // Hiding the Highcharts legend as custom legend is used
		},
		series:
			times_adjusted.length > 0
				? [
						{
							name: "Process Time",
							data: times_adjusted,
							marker: {
								enabled: true,
								radius: 5,
								symbol: "circle",
							},
							threshold: average_time_adjusted, // Setting the threshold to average_time_adjusted
							zoneAxis: "y",
							zones: [
								{
									value: average_time_adjusted,
									color: "green", // Dark green for below average
									fillColor: "#0064002d",
									marker: {
										fillColor: "green", // Dark green markers for below average
										lineColor: "green", // Ensuring marker borders are the same color
									},
								},
								{
									color: "red", // Dark red for above average
									fillColor: "#fc3f3f26",
									marker: {
										fillColor: "red", // Dark red markers for above average
										lineColor: "red", // Ensuring marker borders are the same color
									},
								},
							],
						},
				  ]
				: null,
		plotOptions: {
			areaspline: {
				fillOpacity: 0.3,
				lineWidth: 3,
				states: {
					hover: {
						lineWidth: 4,
					},
				},
				marker: {
					enabled: true,
					lineWidth: 2,
				},
			},
		},
	};

	return (
		<Card style={{ background: "white", padding: "1rem" }}>
			<HighchartsReact highcharts={Highcharts} options={options} />
			{times_adjusted.length > 0 && (
				<div style={{ textAlign: "center", marginTop: "20px" }}>
					<span
						style={{
							display: "inline-flex",
							alignItems: "center",
							marginRight: "30px",
						}}
					>
						<span
							style={{
								backgroundColor: "green",
								display: "inline-block",
								width: "20px",
								height: "20px",
								marginRight: "5px",
								borderRadius: "3px",
							}}
						></span>
						Fast (below average)
					</span>
					<span style={{ display: "inline-flex", alignItems: "center" }}>
						<span
							style={{
								backgroundColor: "red",
								display: "inline-block",
								width: "20px",
								height: "20px",
								marginRight: "5px",
								borderRadius: "3px",
							}}
						></span>
						Slow (above average)
					</span>
				</div>
			)}
		</Card>
	);
};

export default TaktTimeChart;
