import React from "react";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";

import { makeStyles } from "@material-ui/core/styles";
import { usePlaceReport } from "../../../../../core/providers/PlaceReportProvider";
import moment from "moment/moment";
import styles from "./styles";
import { visit3hd } from "../../../../../subapp/utils";

const useStyles = makeStyles(styles);

const StationStats: React.FC = () => {
	const placeReport = usePlaceReport();
	const classes = useStyles();

	return (
		<div>
			{placeReport && (
				<>
					<Chip
						className={classes.chip}
						size="small"
						icon={<Icon fontSize="small">production_quantity_limits</Icon>}
						label={placeReport.lp_qty}
						disabled={placeReport.lp_qty === 0}
					/>
					<Chip
						className={classes.chip}
						size="small"
						icon={<Icon fontSize="small">timelapse</Icon>}
						label={
							placeReport.average_duration &&
							moment.duration(placeReport.average_duration, "s").humanize()
						}
						disabled={!placeReport.average_duration}
					/>
					<Chip
						className={classes.chip}
						size="small"
						icon={<Icon fontSize="small">engineering</Icon>}
						label={placeReport.current_user?.first_name}
						disabled={!placeReport.current_user}
					/>
					{placeReport.oldest_license_plate && (
						<Chip
							className={classes.chip}
							size="small"
							title="Oldest item"
							icon={<Icon fontSize="small">elderly</Icon>}
							label={
								<span>
									...{placeReport.oldest_license_plate.lp_id.slice(-5)}{" "}
									<small>
										<i>({placeReport.oldest_license_plate.product.part_number})</i>
									</small>
								</span>
							}
							clickable
							onClick={() => visit3hd(`/${placeReport?.oldest_license_plate?.lp_id}`)}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default StationStats;
