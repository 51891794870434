import React, { useContext } from "react";
import { StationReportCardProps } from "./types";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { orderContext } from "../../../core/providers/PlaceReportProvider/context";
import OrderStats from "./charts/OrderStats";
import { visit3hd } from "../../../subapp/utils";

const useStyles = makeStyles(styles);

const QR_BASENAME = process.env.REACT_APP_QR_BASENAME || "3hd.us";

const OrderReportCard: React.FC<StationReportCardProps> = props => {
	const classes = useStyles();
	const orderReport = useContext(orderContext);

	const { beacon_id } = props;

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.header}
				action={
					<Tooltip placement="top" arrow title="More reports">
						<IconButton
							className={classes.invisible}
							color="primary"
							component="a"
							onClick={() => visit3hd(`/${beacon_id}`)}
						>
							{/* <Icon fontSize="large">content_paste_search</Icon> */}
							<Icon fontSize="large">assessment</Icon>
						</IconButton>
					</Tooltip>
				}
				title={`Order - ${orderReport?.product.name}`}
				subheader={
					<Link
						variant="caption"
						color="textSecondary"
						onClick={() => visit3hd(`/${beacon_id}`)}
						target="_BLANK"
					>
						{QR_BASENAME}/...{(beacon_id || "").slice(-5)}
					</Link>
				}
			/>
			<CardContent>
				<OrderStats />
			</CardContent>
		</Card>
	);
};

export default OrderReportCard;
