import {
	useState,
	useImperativeHandle,
	forwardRef,
	ChangeEvent,
	FormEvent,
} from "react";
import { EditProductionOrderDialogComponent } from "./types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {
	editOrder,
	ProductionOrder,
} from "../../core/system/production-orders";
import useNotify from "../../core/hooks/use-notify";
import { Divider, TextField } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
	createStyles({
		inputField: {
			margin: "0.5rem 0",
		},
		divider: {
			margin: "1rem 0 0",
		},
		modalAction: {
			minWidth: "100px",
		},
	});
const useStyles = makeStyles(styles);

const EditProductionOrderDialog: EditProductionOrderDialogComponent = (
	props,
	ref
) => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [orderId, setorderId] = useState<number>(NaN);
	const [formFields, setFormFields] = useState<Partial<ProductionOrder>>({
		requested_qty: 0,
		external_docid: "",
	});

	const classes = useStyles();

	useImperativeHandle(
		ref,
		() => {
			return {
				openDialog: order => {
					const { external_docid, requested_qty } = order;
					setFormFields({
						external_docid,
						requested_qty,
					});
					setorderId(order.id);
					setDialogOpen(!!order);
				},
			};
		},
		[]
	);

	const handleChange =
		(name: keyof Partial<ProductionOrder>) =>
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;
			if (formFields) {
				setFormFields(prev => ({
					...prev,
					[name]: value,
				}));
			}
		};

	const notify = useNotify();
	const [loading, setLoading] = useState(false);

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		if (formFields) {
			try {
				await editOrder(orderId, formFields);
				notify("Production order edited successfully");
				setDialogOpen(false);
				props.setRefetch(true);
			} catch (error) {
				notify(error as Error);
			} finally {
				setLoading(false);
			}
		}
	};

	return (
		<>
			<Dialog
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle>Edit Production Order </DialogTitle>
				<form onSubmit={onSubmit}>
					<DialogContent style={{ paddingTop: 0 }}>
						<TextField
							variant="outlined"
							fullWidth
							color="secondary"
							type="number"
							name="requested_qty"
							label="Edit QTY"
							value={formFields?.requested_qty}
							onChange={handleChange("requested_qty")}
							className={classes.inputField}
							disabled={loading}
							placeholder={`Enter Order Quantity`}
						/>
						<TextField
							variant="outlined"
							fullWidth
							color="secondary"
							type="text"
							name="external_docid"
							label="Edit INTERNAL WO #"
							className={classes.inputField}
							value={formFields?.external_docid}
							onChange={handleChange("external_docid")}
							disabled={loading}
							placeholder={`Enter Internal Work Order`}
						/>

						<Divider className={classes.divider} />
					</DialogContent>
					<DialogActions style={{ padding: "10px 24px 24px" }}>
						<Button
							variant="outlined"
							color="secondary"
							type="button"
							disabled={loading}
							className={classes.modalAction}
							onClick={() => setDialogOpen(false)}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="secondary"
							type="submit"
							disabled={loading}
							className={classes.modalAction}
						>
							{loading ? "Loading..." : "Save"}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default forwardRef(EditProductionOrderDialog);
