import React, { useEffect, useState } from "react";
import { ReportsSectionData, ReportsSectionEditorProps } from "./types";
import validationSchema from "./validation-schema";
import { Formik, useFormik } from "formik";
import Portal from "@material-ui/core/Portal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import StationsInput from "./StationsInput";
import usePlaces from "../../core/hooks/use-places";

const reportOptions = [
	{ label: "Stats", value: "stats" },
	{ label: "Parts Distribution", value: "parts-distribution" },
	{ label: "First time right", value: "first-time-right" },
	{ label: "Quantity", value: "qty" },
	{ label: "Quantity Today", value: "qty-today" },
	{ label: "Station Takt Time", value: "takt-time" },
];

const ReportsSectionEditor: React.FC<ReportsSectionEditorProps> = props => {
	const [, setReadyState] = useState(false);
	const [stationOptions, setStationOptions] = useState<
		{
			id: number;
			beacon_id: string;
			name: string;
			lp_qty: number;
			public_access: boolean;
		}[]
	>([]);
	const [places, loading] = usePlaces();

	const form = useFormik<ReportsSectionData>({
		initialValues: {
			title: props.initialValues?.title || "",
			details: props.initialValues?.details || "",
			reports: props.initialValues?.reports || [],
		},
		onSubmit: props.onSave || (() => {}),
		validationSchema,
	});

	useEffect(() => {
		(() => {
			setStationOptions(
				places.map(place => ({
					id: place.id,
					name: place.name,
					beacon_id: place.beacon_id,
					lp_qty: place.lp_qty,
					public_access: place?.public_access || false,
				}))
			);
		})();
	}, [places]);

	useEffect(() => {
		const immediateHandle = setImmediate(setReadyState);
		return () => clearImmediate(immediateHandle);
	}, []);

	return (
		<Formik {...form} onSubmit={props.onSave || (() => {})}>
			<form onSubmit={form.handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							label="Title"
							placeholder="Section title."
							{...form.getFieldProps("title")}
							fullWidth
							InputLabelProps={{ shrink: true }}
							disabled={props.disabled}
							error={form.touched["title"] && !!form.errors["title"]}
							helperText={form.touched["title"] && form.errors["title"]}
						/>
					</Grid>
					{/* <Grid item xs={12}>
          <TextField
            label="Details (Optional)"
            {...form.getFieldProps('details')}
            fullWidth
            multiline
            rows={2}
            InputLabelProps={{ shrink: true }}
            disabled={props.disabled}
          />
        </Grid> */}
					<Grid item xs={12}>
						<Box py={2}>
							<Typography variant="h6">Add Stations</Typography>
							<Typography
								variant="caption"
								color="textSecondary"
								gutterBottom
								component="div"
							>
								To change order, click and drag{" "}
								<Icon fontSize="small">drag_indicator</Icon> icon up or down.
							</Typography>
							{form.touched["reports"] &&
								typeof form.errors["reports"] === "string" && (
									<Typography variant="caption" color="error">
										{form.errors["reports"]}
									</Typography>
								)}
						</Box>
						<StationsInput
							value={form.values["reports"]}
							onChange={val => {
								form.setFieldValue("reports", val, true);
							}}
							disabled={loading || props.disabled}
							stationOptions={stationOptions}
							reportOptions={reportOptions}
						/>
					</Grid>
				</Grid>
				<Portal
					container={props.actionsContainerRef?.current}
					disablePortal={!props.actionsContainerRef}
				>
					{!!props.onSave && (
						<Button
							onClick={form.submitForm}
							variant="contained"
							color="primary"
							disabled={loading || props.disabled}
						>
							save
						</Button>
					)}
				</Portal>
			</form>
		</Formik>
	);
};

export default ReportsSectionEditor;
