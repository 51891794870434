import React from "react";
import { PlaceReportProviderProps } from "./types";
import { useState, useEffect } from "react";
import { orderContext } from "./context";
import { Cache } from "../../system/store";
import {
	ProductionOrder,
	ProductionOrderStatusReport,
} from "../../system/production-orders/types";
import {
	getProductionOrder,
	getProductionOrderStatusReport,
} from "../../system/production-orders";
import {
	renderError,
	renderLoading,
	renderReloading,
} from "./PlaceReportProvider";
import { useDateRange } from "../../components/DateRangePicker";

const ordersCache = new Cache();

export type OrderStatType = ProductionOrder & ProductionOrderStatusReport;

const OrderReportProvider: React.FC<PlaceReportProviderProps> = props => {
	const { id, reloadInterval } = props;

	const [orderReport, setorderReport] = useState<OrderStatType | null>(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [retryTimestamp, setRetryTimestamp] = useState(0);
	const dateRange = useDateRange();

	const isReloading = loading && !!orderReport && orderReport.id === id;

	useEffect(() => {
		let timeoutHandle: NodeJS.Timeout | null = null;

		(async function loadReport() {
			if (reloadInterval) {
				timeoutHandle && clearTimeout(timeoutHandle); // Clear any pre set timer
				timeoutHandle = setTimeout(loadReport, reloadInterval);
			}

			try {
				setError(null);
				setLoading(true);
				const maxAgeAccepted = (reloadInterval || 0) / 4;
				const report = await getProductionOrder(id);
				const reportStats = await getProductionOrderStatusReport(id, {
					start_date: dateRange?.from,
					end_date: dateRange?.to,
				});
				const orderReport =
					ordersCache.get<OrderStatType>(id, maxAgeAccepted) ||
					ordersCache.set(id, { ...report, ...reportStats }).get<OrderStatType>(id);
				setorderReport(orderReport as OrderStatType);

				setLoading(false);
			} catch (err) {
				console.log(err);
				setLoading(false);
				setError(err as Error);
			}
		})();

		return () => {
			timeoutHandle && clearTimeout(timeoutHandle);
			ordersCache.delete(id);
		};
	}, [dateRange?.from, dateRange?.to, id, reloadInterval, retryTimestamp]);

	return (
		<orderContext.Provider value={orderReport}>
			{loading && !isReloading && renderLoading()}
			{isReloading && renderReloading()}
			{error && renderError(error, () => setRetryTimestamp(Date.now()))}
			{!error && (!loading || isReloading) && props.children}
		</orderContext.Provider>
	);
};

export default OrderReportProvider;
