import randomize from "randomatic";

export type MomenttrackIdType =
	| "document"
	| "license_plate"
	| "activity"
	| "place"
	| "person"
	| "transaction"
	| "product"
	| "unknown";

export interface MomenttrackIdInfo {
	id: string;
	url: string;
	type: MomenttrackIdType;
}

export const MomenttrackIdsMap: { [key: string]: MomenttrackIdType | number } =
	{
		"8": "activity",
		"9": "activity",
		"11": "place",
		"17": "person",
		"25": "license_plate",
		"32": "document",
		"36": "transaction",
		"0": "unknown",
		activity: 9,
		place: 11,
		person: 17,
		license_plate: 25,
		document: 32,
		transaction: 36,
		product: 25,
		unknown: 0,
	};

export const generateIds = (type: MomenttrackIdType, qty: number) => {
	if (qty < 1 || !qty) return [];

	return Array(qty)
		.fill("")
		.map(() => randomize("A0", MomenttrackIdsMap[type as string] as number));
};

function generateId(value: number | string, idType: MomenttrackIdType) {
	if (value === undefined || value === null) return value;

	return value.toString().padStart(MomenttrackIdsMap[idType] as number, "0");
}

export const generateUrl = (value: number, idType: MomenttrackIdType) => {
	const momentTrackId = generateId(value, idType);
	if (momentTrackId === undefined || momentTrackId === null)
		return momentTrackId;

	return `${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${momentTrackId}`;
};

const parseMomenttrackId = (id: string): MomenttrackIdInfo => {
	const parsedId =
		(id || "")
			.trim()
			.replace(/^\/|\/$/g, "")
			.split("/")
			.pop() || "";
	const type =
		(MomenttrackIdsMap[parsedId.length.toString()] as MomenttrackIdType) ||
		"unknown";
	return {
		id: parsedId,
		url: `${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${parsedId}`,
		type,
	};
};

export default parseMomenttrackId;
