// import apiClient from "../ordertrack-client";
import publicApiClient from "../ordertrack-client/public-api-client";

export interface PdfOptions {
	html?: string;
	pdf_mode?: "portrait" | "landscape";
	pdf_name?: string;
	page_size?: "Letter" | "A4";
	page_height?: string;
	page_width?: string;
	margin_top?: string;
	margin_bottom?: string;
	margin_left?: string;
	margin_right?: string;
	disable_smart_shrinking?: null | boolean;
}

const defaultOptions: PdfOptions = {
	disable_smart_shrinking: null,
};
const BASE_URL = process.env.REACT_APP_HTML_2_PDF_URL || "";

async function htmlToPdf(html: string, options?: PdfOptions): Promise<string> {
	const _options = Object.assign(
		{ pdf_name: Date.now().toString() },
		defaultOptions,
		options || {}
	);

	const res = await publicApiClient.post(BASE_URL, { ..._options, html });
	return res.data?.s3_path;
}

export default htmlToPdf;
