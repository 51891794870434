import apiClient from "../ordertrack-client";
import randomize from "randomatic";
import {
	ProductionOrder,
	ProductionOrderLookupResult,
	ProductionOrderResponse,
	ProductionOrderStatusReport,
} from "./types";
import htmlToPdf, { PdfOptions } from "../utils/html-to-pdf";
import singleLabelsTemplate from "./order-label-templates/production-order-label-single";
import labelsSheetTemplate from "./order-label-templates/production-order-labels-sheet";
import madeManySheetTemplate from "./order-label-templates/made-many-sheet";
import stackedLabelTemplate from "./order-label-templates/stack-order-label";
import singleLabelOrderTemplate from "./order-label-templates/single-order-label";
import orderTemplateLabel from "./order-label-templates/order-template-label";
import publicApiClient from "../ordertrack-client/public-api-client";

export async function getAllOrders(
	page?: number,
	perPage?: number
): Promise<ProductionOrderResponse> {
	const currentPage = page ? page : 1;
	const currentPerPage = perPage ? perPage : 50;
	const res = await apiClient.get(
		`/production_orders?page=${currentPage}&per_page=${currentPerPage}`
	);
	return res.data;
}

export const lookupProductionOrder = async (
	documentId: string
): Promise<ProductionOrderLookupResult> => {
	const res = await publicApiClient.get("/production_orders/lookup", {
		params: { docid: documentId },
	});

	return {
		pick_type: "PRODUCTIONORDER",
		...res.data.data,
	};
};

export const getProductionOrder = async (
	orderId: number
): Promise<ProductionOrder> => {
	const res = await apiClient.get(`/production_orders/${orderId}`);
	return res.data.data;
};

export const getProductionOrderStatusReport = async (
	orderId: number,
	params?: {
		page?: number;
		per_page?: number;
		start_date?: Date;
		end_date?: Date;
	}
): Promise<ProductionOrderStatusReport> => {
	const res = await apiClient.get(
		`/production_orders/${orderId}/status_report`,
		{
			params: { ...params },
		}
	);
	return res.data.data;
};

export async function createOrder(
	product_id: number,
	data: { requested_qty: number; external_docid?: string }
): Promise<ProductionOrder> {
	const res = await apiClient.post("/production_orders", {
		product_id,
		...data,
	});

	return res.data.data;
}
export async function editOrder(
	product_id: number,
	data: Partial<ProductionOrder>
): Promise<ProductionOrder> {
	const res = await apiClient.patch(`/production_orders/${product_id}`, {
		...data,
	});
	return res.data.data;
}

export async function createOrderPermissions(
	id: string | number,
	data: { add: number[]; remove?: number[] }
): Promise<ProductionOrder> {
	const res = await apiClient.patch(
		`/production_orders/${id}/public_view_permissions`,
		{
			...data,
		}
	);

	return res.data.data;
}

export const generateActivitySheets = (
	orders: ProductionOrder[],
	type?: string,
	qr?: string
): Promise<string> => {
	let labelsHtml: string;
	let pdfOptions: PdfOptions = {
		pdf_mode: "portrait",
		pdf_name: `made-many-sheets-${randomize("a0", 12)}`,
		page_height: "280mm",
		page_width: "220mm",
		margin_top: "0mm",
		margin_bottom: "0mm",
		margin_left: "0mm",
		margin_right: "0mm",
		disable_smart_shrinking: true,
	};
	switch (type) {
		case "made-many": {
			labelsHtml = madeManySheetTemplate(orders, "mademanya");
			pdfOptions = {
				...pdfOptions,
				pdf_name: `made-many-sheets-${randomize("a0", 12)}`,
				page_width: "220mm",
			};
			break;
		}
		case "made-auto": {
			labelsHtml = madeManySheetTemplate(orders, "madeitaut");
			pdfOptions = {
				...pdfOptions,
				pdf_name: `made-auto-sheets-${randomize("a0", 12)}`,
				page_width: "220mm",
			};
			break;
		}
		case "made-notes": {
			labelsHtml = madeManySheetTemplate(orders, "madewithnotes");
			pdfOptions = {
				...pdfOptions,
				pdf_name: `made-notes-sheets-${randomize("a0", 12)}`,
				page_width: "220mm",
			};
			break;
		}
		case "stack-order": {
			labelsHtml = stackedLabelTemplate(orders);
			pdfOptions = {
				...pdfOptions,
				pdf_name: `stack-order-${randomize("a0", 12)}`,
				page_width: "204mm",
			};
			break;
		}
		case "production-order": {
			pdfOptions = {
				...pdfOptions,
				pdf_name: `production-order-${randomize("a0", 12)}`,
				page_height: "105.6mm",
				page_width: "50.8mm",
			};
			labelsHtml = singleLabelOrderTemplate(orders);
			break;
		}
		case "order-template": {
			pdfOptions = {
				...pdfOptions,
				pdf_name: `production-order-${randomize("a0", 12)}`,
				page_height: "105.6mm",
				page_width: "50.8mm",
			};
			labelsHtml = orderTemplateLabel(orders, qr);
			break;
		}
		default: {
			labelsHtml = madeManySheetTemplate(orders, "mademanya");
			break;
		}
	}
	return htmlToPdf(labelsHtml, pdfOptions);
};

export function generateOrderLabels(
	orders: ProductionOrder[],
	templateType: "single" | "sheet"
): Promise<string> {
	let labelsHtml = "";
	let pdfOptions: PdfOptions = {};

	switch (templateType) {
		case "single": {
			labelsHtml = singleLabelsTemplate(orders);
			pdfOptions = {
				pdf_mode: "portrait",
				pdf_name: `production-order-labels-${randomize("a0", 12)}`,
				page_width: "66.675mm",
				page_height: "25.4mm",
				margin_top: "0mm",
				margin_bottom: "0mm",
				margin_left: "0mm",
				margin_right: "0mm",
				disable_smart_shrinking: null,
			};
			break;
		}
		case "sheet":
		default: {
			labelsHtml = labelsSheetTemplate(orders, 30);
			pdfOptions = {
				pdf_mode: "portrait",
				pdf_name: `production-order-label-sheets-${randomize("a0", 12)}`,
				page_size: "Letter",
				margin_top: "0mm",
				margin_bottom: "0mm",
				margin_left: "0mm",
				margin_right: "0mm",
				disable_smart_shrinking: null,
			};
			break;
		}
	}

	return htmlToPdf(labelsHtml, pdfOptions);
}
