import apiClient from "../ordertrack-client";
import { LicensePlate } from "./types";

export const lookupLicensePlates = async (
	external_serial_number: string
): Promise<LicensePlate[]> => {
	const res = await apiClient.get("/license_plates/lookup", {
		params: { external_serial_number },
	});
	return res.data.data;
};
